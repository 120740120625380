<template>
  <div>
    <header-page-derek :title="'Devoluciones'" :skeleton="false" />
    <div class="content-devoluciones container-max-width">
      <div>
        <search-order-returns v-if="step == 1" @nextStep="step = $event" />
        <return-order v-if="step == 2" @nextStep="step = $event" />
        <receive-return v-if="step == 3" @nextStep="step = $event" />
        <summary-return v-if="step == 4" @nextStep="step = $event" />
        <!-- <cant-returns-orders v-if="step == 10" @nextStep="step = $event"/> -->
        <footer-returns />
      </div>
    </div>
  </div>
</template>
<script>
import HeaderPageDerek from '@/components/Templates/HeaderPageDerek'
import SearchOrderReturns from '@/components/Returns/SearchOrderReturns'
import FooterReturns from '@/components/Returns/FooterReturns'
// import CantReturnsOrders from '@/components/Returns/CantReturnsOrders'
import ReturnOrder from '@/components/Returns/ReturnOrder'
import ReceiveReturn from '@/components/Returns/ReceiveReturn'
import SummaryReturn from '@/components/Returns/SummaryReturn'
export default {
  components: {
    HeaderPageDerek,
    SearchOrderReturns,
    FooterReturns,
    /*CantReturnsOrders,*/ ReturnOrder,
    ReceiveReturn,
    SummaryReturn,
  },
  data() {
    return {
      step: 1,
    }
  },
  watch: {
    step() {
      window.scrollTo(0, 0)
    },
  },
  mounted() {
    document.title = 'Devoluciones | DEREK Tienda Online'
    // this.$gtag.pageview(this.$route.fullPath)
    window.scrollTo(0, 0)
    this.$store.state.user.status_devolucion = false
  },
}
</script>
