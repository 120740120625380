<template>
  <div class="return-order">
    <div class="go-back-returns">
      <img
        class="pr-3"
        src="@/assets/icons/arrow-left-simple.svg"
        alt="flecha de retroceder"
      />
      <p @click="$route.params.idPedido ? $router.go(-1) : $router.go(0)">
        volver
      </p>
    </div>
    <div class="content-return-order">
      <span class="label-state">Entregado</span>
      <div class="header-return-order">
        <div>
          <p>
            Pedido No.
            <strong>{{ dataDevoluciones.pedido.pedido_id }} </strong> <br />
            Fecha pedido:
            <strong>{{ dataDevoluciones.pedido.pedido_fecha }}</strong
            ><br />
            Fecha entrega:
            <strong>{{ dataDevoluciones.pedido.pedido_entregado_fecha }}</strong
            ><br />
            Último estado:
            <strong
              >{{ dataDevoluciones.pedido.titulo_estado }} -
              {{ dataDevoluciones.pedido.fecha_ultimo_estado }}</strong
            >
          </p>
        </div>
        <div>
          <!-- <button class="btn-go-back" @click="$emit('nextStep', 1)" type=""><img class="pe-2" src="@/assets/icons/arrow-left-simple.svg" alt="flecha de retroceder">Volver</button> -->
        </div>
      </div>
      <div>
        <div class="content-products-return">
          <div class="my-5 pb-4" style="border-bottom: 1px solid #C8D7E5">
            <p class="d-block w-100 my-2 font-weight-bold px-3">
              VAS A INICIAR UN PROCESO DE DEVOLUCIÓN, POR FAVOR TEN PRESENTE LAS
              SIGUIENTES INDICACIONES:
            </p>
            <ul>
              <li>
                El costo del transporte de la devolución y el nuevo envío será
                asumido por Baguer SAS.
              </li>
              <li>Solo puedes realizar una devolución por pedido.</li>
              <li>
                No se aceptan devoluciones de Bisutería (anillos, manillas,
                pulseras, aretes, collares, y cadenas), perfume, ropa interior
                (tops, boxers, pantys, set, medias y bralets), maquillaje y
                trajes de baño.
              </li>
              <li>
                Puedes solicitar devolución hasta 30 días después de la
                confirmación de la entrega de tu pedido.
              </li>
              <li>
                Recuerda conservar y devolver los empaques y etiquetas
                originales.
              </li>
            </ul>
          </div>
          <h4 class="mb-5">Selecciona los productos y motivo de devolución</h4>
          <card-product-return
            v-for="(data, index) in dataDevoluciones.pedido.productos"
            :key="index"
            :falta-motivo="
              faltaMotivo.find(
                e => e.pedido_detalle_id == data.pedido_detalle_id,
              )
                ? true
                : false
            "
            :product="data"
            :disabled-card="!data.permitir_devoluciones"
            @addProduct="addProduct($event)"
            @deleteProduct="deleteProduct($event)"
          />
        </div>
        <div class="tyc-next">
          <div class="tyc-container-return">
            <input v-model="aceptTyC" type="checkbox" name="tycreturns" />
            <p>
              Confirmo que los productos que quiero devolver se encuentra en
              buen estado y cumple con los
              <router-link
                target="_blank"
                :to="{ name: 'GuaranteesReturns' }"
                style="color: #0d6efd"
                >Términos y condiciones.</router-link
              >
            </p>
          </div>
          <div>
            <button
              :disabled="!aceptTyC || productsReturn.length == 0"
              :style="
                aceptTyC && productsReturn.length > 0
                  ? ''
                  : 'opacity: .5; cursor: default;'
              "
              class="confirm-devolution btn-primary"
              type=""
              @click="validateProductsDevoluciones()"
            >
              Confirmar devolución
            </button>
          </div>
        </div>
        <div
          v-if="faltaMotivo.length > 0"
          class="w-100 text-center text-md-end mt-3"
        >
          <span style="color: red;"
            >Algunos productos aun no tienen motivo de devolución</span
          >
        </div>
      </div>
      <div>
        <div v-if="productos_error" class="info-product-devolucion">
          <div v-html="productos_error" />
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import CardProductReturn from '@/components/Returns/CardProductReturn'

import { mapState, mapActions } from 'vuex'
export default {
  components: { CardProductReturn },
  data() {
    return {
      stepReturnOrder: 1,
      aceptTyC: false,
      productsReturn: [],
      faltaMotivo: [],
    }
  },
  computed: {
    ...mapState(['dataDevoluciones']),
    ...mapState('user', ['productos_error', 'dataUser', 'status_devolucion']),
  },
  methods: {
    ...mapActions('user', ['sendReturn']),
    addProduct(data) {
      if (
        this.productsReturn.find(
          e => e.pedido_detalle_id == data.pedido_detalle_id,
        )
      ) {
        this.productsReturn = this.productsReturn.map(e =>
          e.pedido_detalle_id == data.pedido_detalle_id ? data : e,
        )
      } else {
        this.productsReturn.push(data)
      }
    },
    deleteProduct(data) {
      this.productsReturn = this.productsReturn.filter(
        e => e.pedido_detalle_id != data.pedido_detalle_id,
      )
    },
    async validateProductsDevoluciones() {
      if (this.productsReturn.find(e => e.motivo_id == '')) {
        this.faltaMotivo = this.productsReturn.filter(p => p.motivo_id == '')
      } else {
        this.faltaMotivo = []
        let data = {
          pedido_id: this.dataDevoluciones.pedido.pedido_id,
          email: this.dataDevoluciones.pedido.pedido_cliente_email,
          codigoAcceso: this.$route.params.codeOrder,
          arrayproducts: this.productsReturn,
        }
        this.$store.state.loaderDerek = true
        await this.sendReturn(data).then(() => {
          if (this.status_devolucion) {
            window.scrollTo(0, 0)
            this.$emit('nextStep', 3)
          }
          this.$store.state.loaderDerek = false
        })
      }
    },
  },
}
</script>
