<template>
  <div class="return-order">
    <div class="go-back-returns">
      <img
        class="pr-3"
        src="@/assets/icons/arrow-left-simple.svg"
        alt="flecha de retroceder"
      />
      <p @click="$emit('nextStep', 1)">volver</p>
    </div>
    <div class="content-return-order">
      <span class="label-state" style="background-color: #C8D7E5"
        >Devolución</span
      >
      <div class="header-return-order">
        <div>
          <p>
            Pedido No. <strong>215468204 </strong> <br />
            Fecha pedido: <strong>24 de agosto 2020</strong><br />
            Fecha entrega: <strong>28 de agosto 2020</strong><br />
          </p>
        </div>
        <div>
          <!-- <button class="btn-go-back" @click="$emit('nextStep', 1)" type=""><img class="pe-2" src="@/assets/icons/arrow-left-simple.svg" alt="flecha de retroceder">Volver</button> -->
        </div>
      </div>
      <div class="container-info-devolucion">
        <p>
          <strong>El pedido va en camino al almacén</strong>, verificaremos que
          cumpla con la política de devolución y te daremos una respuesta muy
          pronto
        </p>
      </div>
      <div class="data-retuen-pedido">
        <div class="col-12 col-md-6">
          <p class="info-user-order">
            <strong>Dirección de envío</strong> <br />
            Calle 15 #25-39 Edificio La Libertad <br />
            Apto 503 Bloque B <br />
            Bucaramanga, Santander <br />
            3166626512 <br />
          </p>
          <p class="info-user-order">
            <strong>Método de pago</strong> <br />
            Total del pedido: $1.495.000 <br />
            - GIFT CARD: $100.000 <br />
            - Pago PSE: 1.395.000 <br />
          </p>
        </div>
        <div class="col-12 col-md-6">
          <div
            v-for="index in 3"
            :key="index"
            class="container-card-devolucion"
          >
            <simple-card-product :product="product" />
            <div class="state-devolucion">
              <strong>Motivo devolución</strong>: No es mi talla
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import SimpleCardProduct from '@/components/Templates/SimpleCardProduct'
export default {
  components: { SimpleCardProduct },
  data() {
    return {
      product: {
        titulo: 'CARDIGAN TEJIDO COMBINADO DEREK',
        producto_sku: 826274,
        talla: 'XS',
        color: 'UNICO',
        precio: 169990,
        precio_antes: 0,
        producto_imagen:
          'https://cdn.baguer.co/uploads/2021/12/NICOLE-CARDIGAN-TEJIDO-DEREK-UNICO-826274CU.jpg_sujHA66Oza775kxTWkxKVHAwF9N3Wqt4IaWTW8O12J6kc67MLr.jpg',
        color_id: 'CU',
        pedido_detalle_id: 389448,
        permitir_devoluciones: 1,
        url: 'product-826274-CARDIGAN-TEJIDO-COMBINADO-DEREK?color=CU',
      },
    }
  },
}
</script>
