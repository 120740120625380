var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"return-order"},[_c('div',{staticClass:"go-back-returns"},[_c('img',{staticClass:"pr-3",attrs:{"src":require("@/assets/icons/arrow-left-simple.svg"),"alt":"flecha de retroceder"}}),_c('p',{on:{"click":function($event){_vm.$route.params.idPedido ? _vm.$router.go(-1) : _vm.$router.go(0)}}},[_vm._v(" volver ")])]),_c('div',{staticClass:"content-return-order"},[_c('span',{staticClass:"label-state"},[_vm._v("Entregado")]),_c('div',{staticClass:"header-return-order"},[_c('div',[_c('p',[_vm._v(" Pedido No. "),_c('strong',[_vm._v(_vm._s(_vm.dataDevoluciones.pedido.pedido_id)+" ")]),_vm._v(" "),_c('br'),_vm._v(" Fecha pedido: "),_c('strong',[_vm._v(_vm._s(_vm.dataDevoluciones.pedido.pedido_fecha))]),_c('br'),_vm._v(" Fecha entrega: "),_c('strong',[_vm._v(_vm._s(_vm.dataDevoluciones.pedido.pedido_entregado_fecha))]),_c('br'),_vm._v(" Último estado: "),_c('strong',[_vm._v(_vm._s(_vm.dataDevoluciones.pedido.titulo_estado)+" - "+_vm._s(_vm.dataDevoluciones.pedido.fecha_ultimo_estado))])])]),_c('div')]),_c('div',[_c('div',{staticClass:"content-products-return"},[_vm._m(0),_c('h4',{staticClass:"mb-5"},[_vm._v("Selecciona los productos y motivo de devolución")]),_vm._l((_vm.dataDevoluciones.pedido.productos),function(data,index){return _c('card-product-return',{key:index,attrs:{"falta-motivo":_vm.faltaMotivo.find(
              e => e.pedido_detalle_id == data.pedido_detalle_id,
            )
              ? true
              : false,"product":data,"disabled-card":!data.permitir_devoluciones},on:{"addProduct":function($event){return _vm.addProduct($event)},"deleteProduct":function($event){return _vm.deleteProduct($event)}}})})],2),_c('div',{staticClass:"tyc-next"},[_c('div',{staticClass:"tyc-container-return"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.aceptTyC),expression:"aceptTyC"}],attrs:{"type":"checkbox","name":"tycreturns"},domProps:{"checked":Array.isArray(_vm.aceptTyC)?_vm._i(_vm.aceptTyC,null)>-1:(_vm.aceptTyC)},on:{"change":function($event){var $$a=_vm.aceptTyC,$$el=$event.target,$$c=$$el.checked?(true):(false);if(Array.isArray($$a)){var $$v=null,$$i=_vm._i($$a,$$v);if($$el.checked){$$i<0&&(_vm.aceptTyC=$$a.concat([$$v]))}else{$$i>-1&&(_vm.aceptTyC=$$a.slice(0,$$i).concat($$a.slice($$i+1)))}}else{_vm.aceptTyC=$$c}}}}),_c('p',[_vm._v(" Confirmo que los productos que quiero devolver se encuentra en buen estado y cumple con los "),_c('router-link',{staticStyle:{"color":"#0d6efd"},attrs:{"target":"_blank","to":{ name: 'GuaranteesReturns' }}},[_vm._v("Términos y condiciones.")])],1)]),_c('div',[_c('button',{staticClass:"confirm-devolution btn-primary",style:(_vm.aceptTyC && _vm.productsReturn.length > 0
                ? ''
                : 'opacity: .5; cursor: default;'),attrs:{"disabled":!_vm.aceptTyC || _vm.productsReturn.length == 0,"type":""},on:{"click":function($event){return _vm.validateProductsDevoluciones()}}},[_vm._v(" Confirmar devolución ")])])]),(_vm.faltaMotivo.length > 0)?_c('div',{staticClass:"w-100 text-center text-md-end mt-3"},[_c('span',{staticStyle:{"color":"red"}},[_vm._v("Algunos productos aun no tienen motivo de devolución")])]):_vm._e()]),_c('div',[(_vm.productos_error)?_c('div',{staticClass:"info-product-devolucion"},[_c('div',{domProps:{"innerHTML":_vm._s(_vm.productos_error)}})]):_vm._e()])])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"my-5 pb-4",staticStyle:{"border-bottom":"1px solid #C8D7E5"}},[_c('p',{staticClass:"d-block w-100 my-2 font-weight-bold px-3"},[_vm._v(" VAS A INICIAR UN PROCESO DE DEVOLUCIÓN, POR FAVOR TEN PRESENTE LAS SIGUIENTES INDICACIONES: ")]),_c('ul',[_c('li',[_vm._v(" El costo del transporte de la devolución y el nuevo envío será asumido por Baguer SAS. ")]),_c('li',[_vm._v("Solo puedes realizar una devolución por pedido.")]),_c('li',[_vm._v(" No se aceptan devoluciones de Bisutería (anillos, manillas, pulseras, aretes, collares, y cadenas), perfume, ropa interior (tops, boxers, pantys, set, medias y bralets), maquillaje y trajes de baño. ")]),_c('li',[_vm._v(" Puedes solicitar devolución hasta 30 días después de la confirmación de la entrega de tu pedido. ")]),_c('li',[_vm._v(" Recuerda conservar y devolver los empaques y etiquetas originales. ")])])])
}]

export { render, staticRenderFns }