<template>
  <div class="product-return-order">
    <div v-if="disabledCard" class="cant-return-product" />
    <div
      class="d-flex align-items-center position-relative order-1  mb-3 mb-md-0"
    >
      <input
        v-model="checkItem"
        type="checkbox"
        class="input-select-product-return-order"
        checkmark="checkmark"
        :value="true"
        @click="checkItem == true ? (checkItem = false) : ''"
      />
      <span
        :class="checkItem == true ? 'checkmark' : 'checkmark-unselect'"
        @click="checkItem == true ? (checkItem = false) : (checkItem = true)"
      ></span>
    </div>
    <div
      class="img-product-return-order-return order-3 order-md-2 mb-3 mb-md-0"
    >
      <img class="" :src="product.producto_imagen" :alt="product.titulo" />
    </div>
    <div
      class="col-lg-6 col-xl-7 data-product-return-order ps-0 ps-md-3 order-2 order-md-3 mb-3 mb-md-0"
    >
      <p class="title-product-return-order">
        {{ product.titulo.toLowerCase().replace(/^\w/, d => d.toUpperCase()) }}
      </p>
      <span class="detail-product-return-order"
        >SKU: {{ product.producto_sku }}<br />
        Talla: {{ product.talla }} <br />
        Color: {{ product.color }} <br />
      </span>
      <p class="price-product-return-order">
        ${{ new Intl.NumberFormat('de-DE').format(product.precio) }}
      </p>
    </div>
    <div class="mx-auto me-md-0 my-md-auto order-4 mb-3">
      <div v-if="disabledCard" class="select-option-disabled">
        No se permite devolución
        <img class="ps-2" src="@/assets/icons/icon-info.svg" alt="" />
      </div>
      <select
        v-if="checkItem"
        v-model="selectedMotivo"
        class="select-option-return-order"
        :style="faltaMotivo ? 'border-bottom: 1px solid red;' : ''"
      >
        <option value="">Motivo devolución</option>
        <option
          v-for="(item, index) in dataDevoluciones.motivos_devolucion"
          :key="index"
          :value="item.id"
          >{{ item.descripcion }}</option
        >
      </select>
    </div>
  </div>
</template>
<script>
import { mapState } from 'vuex'
export default {
  props: {
    disabledCard: {
      type: Boolean,
      required: false,
    },
    product: {
      type: Object,
      required: false,
      default: () => [],
    },
    faltaMotivo: {
      type: Boolean,
      required: false,
    },
  },
  data() {
    return {
      checkItem: false,
      selectedMotivo: '',
    }
  },
  computed: {
    ...mapState(['dataDevoluciones']),
  },
  watch: {
    selectedMotivo() {
      if (this.selectedMotivo != '') {
        let data = {
          pedido_detalle_id: this.product.pedido_detalle_id,
          motivo_id: this.selectedMotivo,
        }
        this.$emit('addProduct', data)
      }
    },
    checkItem() {
      if (!this.checkItem) {
        let data = {
          pedido_detalle_id: this.product.pedido_detalle_id,
          motivo_id: this.selectedMotivo,
        }
        this.$emit('deleteProduct', data)
        this.selectedMotivo = ''
      } else {
        let data = {
          pedido_detalle_id: this.product.pedido_detalle_id,
          motivo_id: this.selectedMotivo,
        }
        this.$emit('addProduct', data)
      }
    },
  },
}
</script>
