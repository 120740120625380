<template>
  <div class="mb-3">
    <div class="text-info-devoluciones">
      <strong>DEVOLUCIONES GRATIS:</strong><br /><br />
      <ul>
        <li>
          El costo del transporte de la devolución y el nuevo envío será asumido
          por Baguer SAS.
        </li>
        <li>Solo puedes realizar una devolución por pedido.</li>
        <li>
          No se aceptan devoluciones de Bisutería (anillos, manillas, pulseras,
          aretes, collares, y cadenas), perfume, ropa interior (tops, boxers,
          pantys, set, medias y bralets), maquillaje y trajes de baño.
        </li>
        <li>
          Puedes solicitar devolución hasta 30 días después de la confirmación
          de la entrega de tu pedido.
        </li>
        <li>
          Recuerda conservar y devolver los empaques y etiquetas originales.
        </li>
      </ul>
    </div>
    <div class="text-center my-5">
      <p v-if="stepSearchOrder != 4" class="title-search-devoluciones">
        Iniciar devolución
      </p>
      <div v-if="stepSearchOrder == 1" class="form-devoluciones">
        <p class="text-search-devoluciones">
          Ingresa tu correo electrónico y el número de pedido para iniciar el
          proceso de devolución.
        </p>
        <div
          class="payment-input p-0 mb-4 mx-auto position-relative"
          style="max-width: 320px; border-bottom: none; background-color: transparent"
        >
          <input
            id="email"
            v-model="email"
            class="form-devoluciones-input"
            placeholder="Correo electrónico"
            type="email"
          />
          <span v-if="email" class="newlatter-error-input"
            >Correo electrónico</span
          >
        </div>
        <div class="input-info-devolucion">
          <div
            class="payment-input p-0 mb-4 mx-auto position-relative"
            style="max-width: 320px; border-bottom: none; background-color: transparent"
          >
            <input
              v-model="nPedido"
              class="form-devoluciones-input"
              type="number"
              placeholder="Número de pedido"
            />
            <span v-if="nPedido" class="newlatter-error-input"
              >Número de pedido</span
            >
          </div>
          <img
            id="devoluciones-info"
            class="icon-info-devolucion"
            src="@/assets/icons/icon-info.svg"
            alt=""
          />
          <b-popover target="devoluciones-info" :triggers="['hover']">
            <div>
              <p><strong>El número de pedido</strong></p>
              <span>
                Podrás encontrar tu número de pedido en el correo electrónico de
                confirmación de pedido. <br />
                <br />
                ¿No has recibido el correo electrónico de confirmación de
                pedido? Revisa tu carpeta de correo electrónico no deseado, tal
                vez esté allí. Si tu cuenta de correo electrónico es de gmail,
                revisa la carpeta de promociones.<br />
                <br />
                Si hace poco que has realizado el pedido, ten en cuenta que aún
                tardaremos unos 5 minutos en procesarlo.
              </span>
            </div>
          </b-popover>
        </div>
        <button
          class="form-devoluciones-button btn-primary"
          type=""
          @click="checkOrder()"
        >
          Continuar
        </button>
      </div>
      <div v-if="stepSearchOrder == 2" class="form-devoluciones">
        <div class="py-4">
          Hemos enviado un código de acceso a tu correo electrónico
        </div>
        <div class="form-devoluciones-code">
          <div
            class="payment-input p-0 mb-4 mx-0 position-relative"
            style="max-width: 320px; border-bottom: none; background-color: transparent"
          >
            <input
              v-model="codeOrder"
              class="w-100 form-devoluciones-input-code"
              type="number"
              placeholder="Código de seguridad"
            />
            <span v-if="codeOrder" class="newlatter-error-input"
              >Escribe el código</span
            >
          </div>
          <div>
            <button
              class="w-100 form-devoluciones-button-code btn-primary"
              type=""
              @click="validateCodeOrder()"
            >
              Verificar
            </button>
          </div>
        </div>
      </div>
      <div v-if="stepSearchOrder == 4" class="form-devoluciones">
        <p class="text-center px-4 d-block w-100">
          Tu pedido <b class="bold"> No. {{ nPedido }} </b> se entregó el
          {{ fechaPedidoEntregado }} y
          <b class="bold"> ya no está disponible </b> para iniciar el proceso de
          devolución.
        </p>
        <p class="text-center px-4 d-block w-100 mb-5">
          Recuerda que el derecho al retracto son 30 días a partir de la fecha
          de entrega.
        </p>
        <button class="btn-primary px-5 py-2" @click="tryAgain()">
          <b>Hacer otra consulta</b>
        </button>
      </div>
      <div v-if="msgError" class="msg-error-return">
        <p style="color: tomato">{{ messagerError }}</p>
      </div>
    </div>
  </div>
</template>
<script>
import { BPopover } from 'bootstrap-vue'

import { mapState } from 'vuex'
export default {
  components: { BPopover },
  data() {
    return {
      openPopover: false,
      stepSearchOrder: 1,
      email: '',
      nPedido: '',
      codeOrder: '',
      msgError: false,
      messagerError: '',
      fechaPedidoEntregado: '',
    }
  },
  computed: {
    ...mapState('user', ['dataUser']),
  },
  mounted() {
    // this.$gtag.pageview(this.$route.fullPath)
    if (this.$route.params.idPedido && this.dataUser) {
      this.nPedido = this.$route.params.idPedido
      this.codeOrder = this.$route.params.idPedido
      this.email = this.dataUser.correo
      this.validateCodeOrder()
    }
  },
  methods: {
    tryAgain() {
      this.openPopover = false
      this.stepSearchOrder = 1
      this.email = ''
      this.nPedido = ''
      this.codeOrder = ''
      this.msgError = false
      this.messagerError = ''
      this.fechaPedidoEntregado = ''
    },
    checkOrder() {
      this.$store.state.loaderDerek = true
      let data = new FormData()
      data.append('pedido_id', this.nPedido)
      data.append('email', this.email)
      this.axios({
        method: 'POST',
        headers: { 'content-type': 'application/x-www-form-urlencoded' },
        url: '/orders/returns/generateTokenAccess',
        data: data,
      })
        .then(response => {
          this.$store.state.loaderDerek = false
          if (response.data.pedido) {
            this.$store.state.dataDevoluciones = response.data
            this.nPedido = ''
            this.codeOrder = ''
            this.email = ''
            this.$emit('nextStep', 2)
          } else {
            if (response.data.pedido_entregado_fecha) {
              this.stepSearchOrder = 4
              this.fechaPedidoEntregado = response.data.pedido_entregado_fecha
            } else {
              this.stepSearchOrder += 1
            }
            this.msgError = false
          }
        })
        .catch(err => {
          if (err.response.status == 500) {
            this.messagerError =
              'Parece que algo salió mal, intentalo más tarde'
          } else {
            let message = ''
            for (const property in err.response.data.errors) {
              message = message + err.response.data.errors[property][0] + ' '
            }
            this.messagerError = message
          }
          this.msgError = true
          this.$store.state.loaderDerek = false
        })
    },
    validateCodeOrder() {
      this.$store.state.loaderDerek = true
      let data = new FormData()
      data.append('pedido_id', this.nPedido)
      data.append('email', this.email)
      data.append('codigoAcceso', this.codeOrder)
      this.axios({
        method: 'POST',
        headers: { 'content-type': 'application/x-www-form-urlencoded' },
        url: '/orders/returns/validateTokenAccess',
        data: data,
      })
        .then(response => {
          this.$route.params.codeOrder = this.codeOrder
          if (response.data.pedido_entregado_fecha) {
            this.stepSearchOrder = 4
            this.fechaPedidoEntregado = response.data.pedido_entregado_fecha
          } else if (response.data.pedido_devolucion) {
            this.stepSearchOrder = 4
          } else {
            this.$store.state.dataDevoluciones = response.data
            this.nPedido = ''
            this.codeOrder = ''
            this.email = ''
            this.$emit('nextStep', 2)
          }
          this.$store.state.loaderDerek = false
        })
        .catch(err => {
          if (err.response.status == 500) {
            this.messagerError =
              'Parece que algo salió mal, intentalo más tarde'
          } else {
            let message = ''
            for (const property in err.response.data.errors) {
              message = message + err.response.data.errors[property][0] + ' '
            }
            this.messagerError = message
          }
          this.msgError = true
          this.$store.state.loaderDerek = false
        })
    },
  },
}
</script>
